import {HIDE_SPINNER, SHOW_ERROR, SHOW_SPINNER, SHOW_SUCCESS} from '../../actionConstants';

export const messages = {
  LOGIN_START: [
    {action: SHOW_SPINNER},
  ],
  LOGIN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Login successful!', type: 'success'}},
    },
  ],
  LOGIN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Authentication failed!', type: 'error'}},
    },
  ],
  LOGOUT_START: [
    {action: SHOW_SPINNER},
  ],
  LOGOUT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Logout successful!', type: 'success'}},
    },
  ],
  LOGOUT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Logout failed!', type: 'warn'}},
    },
  ],
  FORGOT_PASSWORD_START: [
    {action: SHOW_SPINNER},
  ],
  FORGOT_PASSWORD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Password reset successful!', type: 'success'}},
    },
  ],
  FORGOT_PASSWORD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Password reset failed!', type: 'error'}},
    },
  ],
  GET_USER_PROFILE_START: [
    {action: SHOW_SPINNER},
  ],
  GET_USER_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
  ],
  GET_USER_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please try in 5 min to login!', type: 'warn'}},
    },
  ],
  UPDATE_USER_PROFILE_START: [
    {action: SHOW_SPINNER},
  ],
  UPDATE_USER_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_USER_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Profile failed to update!', type: 'error'}},
    },
  ],
  UPDATE_TIMESHEET_ROW_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TIMESHEET_ROW_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet failed to update!', type: 'error'}},
    },
  ],
  CREATE_TIMESHEET_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet created successfully!', type: 'success'}},
    },
  ],
  CREATE_TIMESHEET_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet failed to create!', type: 'error'}},
    },
  ],
  UPDATE_TIMESHEET_STATUS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet status updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TIMESHEET_STATUS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet status failed to update!', type: 'error'}},
    },
  ],
  UPDATE_TIMESHEET_APPROVER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Approver updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TIMESHEET_APPROVER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Approver failed to update!', type: 'error'}},
    },
  ],
  SUBMIT_TIMESHEET_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet submitted successfully!', type: 'success'}},
    },
  ],
  SUBMIT_TIMESHEET_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet failed to submit!', type: 'error'}},
    },
  ],
  CREATE_EMPLOYEE_ALL_FIELDS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'User created successfully!', type: 'success'}},
    },
  ],
  CREATE_EMPLOYEE_ALL_FIELDS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'User failed to create!', type: 'error'}},
    },
  ],
  UPDATE_EMPLOYEE_ALL_FIELDS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'User updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_EMPLOYEE_ALL_FIELDS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'User failed to update!', type: 'error'}},
    },
  ],
  UPDATE_ADMIN_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Admin profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_ADMIN_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Admin profile failed to update!', type: 'error'}},
    },
  ],
  UPDATE_EMPLOYEE_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_EMPLOYEE_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Profile update failed!', type: 'error'}},
    },
  ],
  CREATE_JOB_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job code created successfully!', type: 'success'}},
    },
  ],
  CREATE_JOB_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job code create failed!', type: 'error'}},
    },
  ],
  UPDATE_JOB_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job code updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_JOB_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job code update failed!', type: 'error'}},
    },
  ],
  CREATE_COST_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Cost code created successfully!', type: 'success'}},
    },
  ],
  CREATE_COST_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Cost code failed to create!', type: 'error'}},
    },
  ],
  UPDATE_COST_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Cost code updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_COST_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Cost code failed to update!', type: 'error'}},
    },
  ],
  UPDATE_IRS_MILEAGE_RATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'IRS mileage rate updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_IRS_MILEAGE_RATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'IRS mileage rate update failed!', type: 'error'}},
    },
  ],
  UPDATE_OTHER_EXPENSE_TYPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense type updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_OTHER_EXPENSE_TYPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense type failed to update!', type: 'error'}},
    },
  ],
  CREATE_EXPENSE_TYPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense type created successfully!', type: 'success'}},
    },
  ],
  CREATE_EXPENSE_TYPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense type failed to create!', type: 'error'}},
    },
  ],
  CREATE_EXPENSE_REPORT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report created successfully!', type: 'success'}},
    },
  ],
  CREATE_EXPENSE_REPORT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report failed to create!', type: 'error'}},
    },
  ],
  UPDATE_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report saved successfully!', type: 'success'}},
    },
  ],
  UPDATE_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report failed to save!', type: 'error'}},
    },
  ],
  SUBMIT_EXPENSE_REPORT_BY_EMPLOYEE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report submitted successfully!', type: 'success'}},
    },
  ],
  SUBMIT_EXPENSE_REPORT_BY_EMPLOYEE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report failed to submit!', type: 'error'}},
    },
  ],
  REVIEW_EXPENSE_REPORT_BY_SUPERVISOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report reviewed successfully!', type: 'success'}},
    },
  ],
  REVIEW_EXPENSE_REPORT_BY_SUPERVISOR_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report failed to review!', type: 'error'}},
    },
  ],
  REVIEW_EXPENSE_REPORT_BY_ADMIN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report reviewed successfully!', type: 'success'}},
    },
  ],
  REVIEW_EXPENSE_REPORT_BY_ADMIN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report failed to review!', type: 'error'}},
    },
  ],
  UPDATE_EXPENSE_REPORT_ROWS_FOR_SUYASH_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report saved successfully!', type: 'success'}},
    },
  ],
  UPDATE_EXPENSE_REPORT_ROWS_FOR_SUYASH_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report failed to save!', type: 'error'}},
    },
  ],
  UPLOAD_EXPENSE_REPORT_RECEIPT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense report receipt uploaded successfully!', type: 'success'}},
    },
  ],
  UPLOAD_EXPENSE_REPORT_RECEIPT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense report receipt failed to upload!', type: 'error'}},
    },
  ],
  CREATE_PROJECT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Project  created successfully!', type: 'success'}},
    },
  ],
  CREATE_PROJECT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Project  create failed!', type: 'error'}},
    },
  ],
  UPDATE_PROJECT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Project  updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_PROJECT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Project  update failed!', type: 'error'}},
    },
  ],
  CREATE_PROJECT_REPORT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Project Report created successfully!', type: 'success'}},
    },
  ],
  CREATE_PROJECT_REPORT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Project Report create failed!', type: 'error'}},
    },
  ],
  UPDATE_PROJECT_REPORT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Project report updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_PROJECT_REPORT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Project report updated failed!', type: 'error'}},
    },
  ],
  UPDATE_PHOTO_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Photo upload successfully!', type: 'success'}},
    },
  ],
  UPDATE_PHOTO_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Photo upload failed!', type: 'error'}},
    },
  ],
  CREATE_APPENDIX_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Appendix created successfully!', type: 'success'}},
    },
  ],
  CREATE_APPENDIX_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Appendix failed to create!', type: 'error'}},
    },
  ],
  UPDATE_APPENDIX_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Appendix  updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_APPENDIX_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Appendix  update failed!', type: 'error'}},
    },
  ],
  CREATE_TVHG_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Tvhg created successfully!', type: 'success'}},
    },
  ],
  CREATE_TVHG_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Tvhg create failed!', type: 'error'}},
    },
  ],
  UPDATE_TVHG_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Tvhg  updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TVHG_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Tvhg  update failed!', type: 'error'}},
    },
  ],
};
