import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {
  ROUTE_TIMESHEET_CONFIG_JOB_CODE_CREATE,
  ROUTE_TIMESHEET_CONFIG_JOB_CODE_EDIT, ROUTE_TIMESHEET_CONFIG_NEW_JOB_CODE_CREATE,
  ROUTE_TIMESHEET_CONFIG_PROJECT_CREATE,
  ROUTE_TIMESHEET_CONFIG_PROJECT_EDIT,
  ROUTE_TIMESHEET_CONFIG_WITH_TAB_PARAM,
} from '../../RoutePathConstant';

const TimesheetConfig = lazy(() =>
  import('../../../screens/TimesheetConfig')
    .then(module => ({default: module.TimesheetConfig})));

const EditJobCode = lazy(() =>
  import('../../../screensComponents/TimesheetConfigScreen/JobCodeTable/EditJobCode')
    .then(module => ({default: module.EditJobCode})));

const CreateJobCode = lazy(() =>
  import('../../../screensComponents/TimesheetConfigScreen/JobCodeTable/CreateJobCode')
    .then(module => ({default: module.CreateJobCode})));

const CreateProject = lazy(() =>
  import('../../../screensComponents/TimesheetConfigScreen/ProjectTable/CreateProject')
    .then(module => ({default: module.CreateProject})));

const EditProject = lazy(() =>
  import('../../../screensComponents/TimesheetConfigScreen/ProjectTable/EditProject')
    .then(module => ({default: module.EditProject})));

const CreateNewJobCode = lazy(() =>
  import('../../../screensComponents/TimesheetConfigScreen/JobTable/CreateNewJobCode')
    .then(module => ({default: module.CreateNewJobCode})));

const TimesheetConfigNavigation = [
  {
    path: ROUTE_TIMESHEET_CONFIG_WITH_TAB_PARAM,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <TimesheetConfig />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_CONFIG_JOB_CODE_EDIT,
    loader: authLoader,
    element: <Suspense fallback={<Spin />}>
      <EditJobCode />
    </Suspense>,
  },
  {
    path: ROUTE_TIMESHEET_CONFIG_JOB_CODE_CREATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateJobCode />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_CONFIG_PROJECT_CREATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateProject />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_CONFIG_PROJECT_EDIT,
    loader: authLoader,
    element: <Suspense fallback={<Spin />}>
      <EditProject />
    </Suspense>,
  },

  {
    path: ROUTE_TIMESHEET_CONFIG_NEW_JOB_CODE_CREATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateNewJobCode />
      </Suspense>
    ),
  },
];

export default TimesheetConfigNavigation;
