import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_DESIGN_STORM_LOOKUP_ALL_API,
  RQ_REGISTER_DESIGN_STORM_API,
  RQ_UPDATE_HYDROLOGIC_INFORMATION_FIELD_BY_ID_API,
} from '@app/reactQuery/apis/constants';
import {useQuery} from '@tanstack/react-query';

export const RQRegisterDesignStorm = async ({
  designStorm,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_DESIGN_STORM_API,
    JSON.stringify({...designStorm}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQUseGetDesignStormLookupData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_design_storm_lookup_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_DESIGN_STORM_LOOKUP_ALL_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};

export const RQUpdateHydrologicInformationField = async ({
  hydrologicDetails,
  tokenData,
  id,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_HYDROLOGIC_INFORMATION_FIELD_BY_ID_API,
    JSON.stringify({...hydrologicDetails}),
    tokenData?.access_token,
    {id},
    signal,
  );
};
