import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_TVHG_CONFIG_WITH_TAB_PARAM} from '../../RoutePathConstant';

const TvhgConfig = lazy(() =>
  import('../../../screens/TvhgConfig')
    .then(module => ({default: module.TvhgConfig})));

const TvhgConfigNavigation = [
  {
    path: ROUTE_TVHG_CONFIG_WITH_TAB_PARAM,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <TvhgConfig />
      </Suspense>
    ),
  },
];

export default TvhgConfigNavigation;
