export const RQ_REGISTER_DESIGN_STORM_API = {
  url: 'api/v1/tvhgconfig/add/designstorm',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_DESIGN_STORM_LOOKUP_ALL_API = {
  url: 'api/v1/tvhgconfig/designstorm/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_HYDROLOGIC_INFORMATION_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/update/hydrologicinformation/field',
  method: 'PUT',
  auth: true,
};
