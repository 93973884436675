export const RQ_REGISTER_TVHG_API = {
  url: 'api/v1/tvhginput/register',
  method: 'POST',
  auth: true,
};

export const RQ_GET_PAGEABLE_ALL_TVHG_API = {
  url: 'api/v1/tvhginput/pageable/lookup/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TVHG_BY_ID_API = {
  url:'api/v1/tvhginput/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_ALL_FIELDS_BY_TVHG_API = {
  url: 'api/v1/tvhginput/<%= id %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_REGISTER_STRUCTURE_INFORMATION_BY_TVHG_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/add/structureinformation',
  method: 'POST',
  auth: true,
};

export const RQ_GET_TVHG_CONFIG_LOOKUP_ALL_API = {
  url: 'api/v1/tvhgconfig/lookup',
  method: 'GET',
  auth: true,

};
export const RQ_PUT_UPDATE_PROJECT_INFORMATION_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/update/projectinformation/field',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_UPDATE_STRUCTURE_INFORMATION_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/structureinformation/<%= structureInformationId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_REGISTER_PIPE_INFORMATION_BY_TVHG_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/add/pipeinformation',
  method: 'POST',
  auth: true,
};

export const RQ_REGISTER_STRUCTURE_DRAWING_DATA_BY_TVHG_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/add/structuredrawingdata',
  method: 'POST',
  auth: true,
};

export const RQ_PUT_UPDATE_STRUCTURE_DRAWING_DATA_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/structuredrawingdata/<%= structureDrawingDataId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_REGISTER_PIPE_DRAWING_DATA = {
  url: 'api/v1/tvhginput/<%= id %>/add/pipedrawinginformation',
  method: 'POST',
  auth: true,
};

export const RQ_PUT_UPDATE_PIPE_DRAWING_DATA_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/pipedrawinginformation/<%= pipeDrawingInformationId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_UPDATE_PIPE_INFORMATION_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/pipeinformation/<%= pipeInformationId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_REGISTER_INLET_CONTROL_BY_TVHG_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/add/inletcontrolparameter',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_UPDATE_INLET_CONTROL_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/inletcontrolparameter/<%= inletControlParameterId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_UPDATE_OUTLET_DRAWING_INFORMATION_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/update/outletdrawinginformation/field',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_FLOW_PATH_TITLE_FIELD_BY_ID_API = {
  url: 'api/v1/tvhginput/<%= id %>/update/flowpathdrawinginformation/field',
  method: 'PUT',
  auth: true,
};
