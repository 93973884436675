export const RQ_GET_PAGEABLE_ALL_JOB_CODES_API = {
  url: 'api/v1/jobcode/pageable/lookup/search/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_ALL_JOB_CODES_API = {
  url: 'api/v1/jobcode/lookup/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_ALL_JOB_CODES_BY_SEARCH_API = {
  url: 'api/v1/jobcode/lookup/search/all?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_REGISTER_JOB_CODE_API = {
  url: 'api/v1/jobcode/register',
  method: 'POST',
  auth: true,
};

export const RQ_CREATE_COST_CODE_BY_JOB_CODE_API = {
  url: 'api/v1/jobcode/<%= id %>/add/costcode',
  method: 'POST',
  auth: true,
};

export const RQ_GET_JOB_CODE_BY_ID_API = {
  url: 'api/v1/jobcode/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_JOB_CODE_STATUS_API = {
  url: 'api/v1/jobcode/<%= id %>/update/status',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_JOB_CODE_DESCRIPTION_STATUS_AND_API = {
  url: 'api/v1/jobcode/<%= id %>/update/description/and/status',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_COST_CODE_STATUS_API = {
  url: 'api/v1/jobcode/<%= jobCodeId %>/costcode/<%= id %>/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_COST_CODE_BY_JOB_CODE_ID_API = {
  url: 'api/v1/jobcode/<%= id %>/costcode/lookup/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_PAGEABLE_COST_CODE_BY_JOB_CODE_ID_API = {
  url: 'api/v1/jobcode/<%= id %>/costcode/lookup/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
};

export const RQ_GET_ALL_JOB_CODES_BY_WATCHER_API = {
  url: 'api/v1/jobcode/lookup/all/bywatcher/<%= watcherId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_JOB_CODE_BY_PROJECT_ID_API = {
  url: 'api/v1/jobcode/project/lookup/search/all?',
  method: 'GET',
  auth: true,
  search: true,
};
