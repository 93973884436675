import React from 'react';
import {
  BuildOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  ROUTE_DASHBOARD,
  ROUTE_EXPENSE_REPORT,
  ROUTE_EXPENSE_REPORT_FOR_MANAGER,
  ROUTE_PROFILE,
  ROUTE_PROJECT_REPORT,
  ROUTE_TIMESHEET,
  ROUTE_TIMESHEET_CONFIG,
  ROUTE_TIMESHEET_CONFIG_WITH_TAB_PROJECT,
  ROUTE_TIMESHEET_REVIEW_FOR_MANAGER, ROUTE_TVHG,
  ROUTE_WATCH,
  ROUTE_WATCH_WITH_TAB_JOB_CODE,
} from '@app/navigations/RoutePathConstant';

export const ManagerMenuList = [
  {key: ROUTE_DASHBOARD, path: ROUTE_DASHBOARD, label: 'Dashboard', icon: <DashboardOutlined />},
  {
    key: ROUTE_TIMESHEET,
    path: ROUTE_TIMESHEET_REVIEW_FOR_MANAGER,
    label: 'Timesheet',
    icon: <ClockCircleOutlined />,
  },
  {
    key: ROUTE_EXPENSE_REPORT,
    path: ROUTE_EXPENSE_REPORT_FOR_MANAGER,
    label: 'Expense Report',
    icon: <FileTextOutlined />,
  },
  {
    key: ROUTE_PROJECT_REPORT,
    path: ROUTE_PROJECT_REPORT,
    label: 'Project Report',
    icon: <FileProtectOutlined />,
  },
  {
    key: ROUTE_WATCH,
    path: ROUTE_WATCH_WITH_TAB_JOB_CODE,
    label: 'Watch',
    icon: <EyeOutlined />,
  },
  {
    key: ROUTE_PROFILE,
    path: ROUTE_PROFILE,
    label: 'Profile',
    icon: <UserOutlined />,
  },
  {
    key: ROUTE_TIMESHEET_CONFIG,
    path: ROUTE_TIMESHEET_CONFIG_WITH_TAB_PROJECT,
    label: 'Timesheet Config',
    icon: <FieldTimeOutlined />,
  },
  {
    key:ROUTE_TVHG,
    path: ROUTE_TVHG,
    label: 'Tvhg',
    icon: <BuildOutlined />,
  },
];
