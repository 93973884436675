import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_REGISTER_STRUCTURE_TYPE_API} from '@app/reactQuery/apis/constants/rqStructureTypeAPIConstants';

export const RQRegisterStructureType = async ({
  structureType,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_STRUCTURE_TYPE_API,
    JSON.stringify({...structureType}),
    tokenData?.access_token,
    null,
    signal,
  );
};
