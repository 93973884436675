import {ROUTE_TVHG, ROUTE_TVHG_CREATE, ROUTE_TVHG_VIEW} from '@app/navigations/RoutePathConstant';
import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '@app/navigations/loader';

const Tvhg = lazy(() =>
  import('../../../screens/Tvhg')
    .then(module => ({default: module.Tvhg})));

const CreateTvhg = lazy(() =>
  import('../../../screens/Tvhg')
    .then(module => ({default: module.CreateTvhg})));

const ViewTvhg = lazy(() =>
  import('../../../screens/Tvhg')
    .then(module => ({default: module.ViewTvhg})));

const TvhgNavigation = [
  {
    path: ROUTE_TVHG,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Tvhg />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TVHG_CREATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateTvhg />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TVHG_VIEW,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewTvhg />
      </Suspense>
    ),
  },
];
export default TvhgNavigation;
