import React from 'react';
import {RootNavigator} from '@app/navigations';
import {ConfigProvider} from 'antd';
import {PrimaryTheme} from '@app/style';
import {QueryClientProvider} from '@tanstack/react-query';
import configureStore from '@app/redux/store/configureStore';
import {Provider} from 'react-redux';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import GlobalComponent from '@app/components/global/GlobalComponent';
import MainProvider from '@app/navigations/provider/MainProvider';
import {getQueryClient} from '@app/reactQuery/rqClient';

const store = configureStore();

const App = () => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <Provider store = {store}>
        <ConfigProvider theme={PrimaryTheme}>
          <MainProvider>
            <GlobalComponent />
            <RootNavigator />
            <ReactQueryDevtools />
          </MainProvider>
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
