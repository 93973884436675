import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_PAGEABLE_ALL_TVHG_API,
  RQ_GET_TVHG_BY_ID_API,
  RQ_GET_TVHG_CONFIG_LOOKUP_ALL_API,
  RQ_PUT_UPDATE_INLET_CONTROL_FIELD_BY_ID_API,
  RQ_PUT_UPDATE_OUTLET_DRAWING_INFORMATION_FIELD_BY_ID_API,
  RQ_PUT_UPDATE_PIPE_DRAWING_DATA_FIELD_BY_ID_API,
  RQ_PUT_UPDATE_PIPE_INFORMATION_FIELD_BY_ID_API,
  RQ_PUT_UPDATE_PROJECT_INFORMATION_FIELD_BY_ID_API,
  RQ_PUT_UPDATE_STRUCTURE_DRAWING_DATA_FIELD_BY_ID_API,
  RQ_PUT_UPDATE_STRUCTURE_INFORMATION_FIELD_BY_ID_API,
  RQ_REGISTER_INLET_CONTROL_BY_TVHG_ID_API,
  RQ_REGISTER_PIPE_DRAWING_DATA,
  RQ_REGISTER_PIPE_INFORMATION_BY_TVHG_ID_API,
  RQ_REGISTER_STRUCTURE_DRAWING_DATA_BY_TVHG_ID_API,
  RQ_REGISTER_STRUCTURE_INFORMATION_BY_TVHG_ID_API,
  RQ_REGISTER_TVHG_API,
  RQ_UPDATE_ALL_FIELDS_BY_TVHG_API,
  RQ_UPDATE_FLOW_PATH_TITLE_FIELD_BY_ID_API,
} from '@app/reactQuery/apis/constants/rqTvhgAPIConstants';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';

export const RQPostTvhgRegister = async ({
  tvhg,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_TVHG_API,
    JSON.stringify({...tvhg}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQUseGetPageableAllTvhgData = (payload, options = {}) => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_tvhg', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_TVHG_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    refetchInterval: 30 * 1000,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    ...options,
  });
};

export const RQUseGetTvhgByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_tvhg_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_TVHG_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};

export const RQPutTvhgUpdateAll = async ({
  updateTvhgFields,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_ALL_FIELDS_BY_TVHG_API,
    JSON.stringify({...updateTvhgFields}),
    tokenData?.access_token,
    {id: updateTvhgFields.id},
    signal,
  );
};

export const RQRegisterStructureinformationByTvhgId = async ({
  Structureinformation,
  id,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_STRUCTURE_INFORMATION_BY_TVHG_ID_API,
    JSON.stringify({...Structureinformation}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQUseGetTvhgConfigLookupAllData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_tvhg_config_lookup_all_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_TVHG_CONFIG_LOOKUP_ALL_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 0,
    ...options,
  });
};
export const RQPutProjectInformationField = async ({
  id,
  projectInformation,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_PROJECT_INFORMATION_FIELD_BY_ID_API,
    JSON.stringify({...projectInformation}),
    tokenData?.access_token,
    {id  : id},
    signal,
  );
};

export const RQPutStructureInformationIdField = async ({
  id,
  structureInformationId,
  structureInformation,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_STRUCTURE_INFORMATION_FIELD_BY_ID_API,
    JSON.stringify({...structureInformation}),
    tokenData?.access_token,
    {id  : id,
      structureInformationId :structureInformationId,
    },
    signal,
  );
};

export const RQRegisterPipeInformation = async ({
  PipeInformation,
  id,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_PIPE_INFORMATION_BY_TVHG_ID_API,
    JSON.stringify({...PipeInformation}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};
export const RQRegisterStructureDrawingData = async ({
  StructureDrawingData,
  id,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_STRUCTURE_DRAWING_DATA_BY_TVHG_ID_API,
    JSON.stringify({...StructureDrawingData}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQPutStructureDrawingDataIdField = async ({
  id,
  structureDrawingDataId,
  structureDrawingData,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_STRUCTURE_DRAWING_DATA_FIELD_BY_ID_API,
    JSON.stringify({...structureDrawingData}),
    tokenData?.access_token,
    {id  : id,
      structureDrawingDataId :structureDrawingDataId,
    },
    signal,
  );
};

export const RQRegisterPipeDrawingDataIdField = async ({
  pipeDrawingData,
  id,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_PIPE_DRAWING_DATA,
    JSON.stringify({...pipeDrawingData}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};
export const RQPutPipeInformationIdField = async ({
  id,
  pipeInformationId,
  PipeInformation,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_PIPE_INFORMATION_FIELD_BY_ID_API,
    JSON.stringify({...PipeInformation}),
    tokenData?.access_token,
    {id  : id,
      pipeInformationId :pipeInformationId,
    },
    signal,
  );
};

export const RQRegisterInletControl = async ({
  InletControl,
  id,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_INLET_CONTROL_BY_TVHG_ID_API,
    JSON.stringify({...InletControl}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQPutPipeDrawingDataIdField = async ({
  id,
  pipeDrawingInformationId,
  pipeDrawingData,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_PIPE_DRAWING_DATA_FIELD_BY_ID_API,
    JSON.stringify({...pipeDrawingData}),
    tokenData?.access_token,
    {id  : id,
      pipeDrawingInformationId :pipeDrawingInformationId,
    },
    signal,
  );
};

export const RQUpdateInletControlAllField = async ({
  id,
  inletControlParameterId,
  inletcontrolparameter,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_INLET_CONTROL_FIELD_BY_ID_API,
    JSON.stringify({...inletcontrolparameter}),
    tokenData?.access_token,
    {id  : id,
      inletControlParameterId :inletControlParameterId,
    },
    signal,
  );
};

export const RQPutOutletDrawingInformationField = async ({
  id,
  outletDrawing,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPDATE_OUTLET_DRAWING_INFORMATION_FIELD_BY_ID_API,
    JSON.stringify({...outletDrawing}),
    tokenData?.access_token,
    {id  : id},
    signal,
  );
};

export const RQUpdateFlowPathTitleField = async ({
  flowPathTitle,
  tokenData,
  id,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_FLOW_PATH_TITLE_FIELD_BY_ID_API,
    JSON.stringify({...flowPathTitle}),
    tokenData?.access_token,
    {id},
    signal,
  );
};
