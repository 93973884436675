import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_JOB_CODE_BY_PROJECT_CODE_API,
  RQ_GET_PAGEABLE_ALL_PROJECT_API,
  RQ_GET_PROJECT_BY_ID_API,
  RQ_GET_PROJECT_LIST_API,
  RQ_REGISTER_PROJECT_API,
  RQ_UPDATE_ALL_FIELDS_BY_PROJECT_API,
  RQ_UPDATE_JOB_CODE_BY_PROJECT_CODE_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetPageableAllProjectData = (payload, options = {}) => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_project', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_PROJECT_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    refetchInterval: 30 * 1000,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    ...options,
  });
};

export const RQPostProjectRegister = async ({
  projectCode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_PROJECT_API,
    JSON.stringify({...projectCode}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQUseGetProjectByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_project_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PROJECT_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};

export const RQPutProjectUpdateAll = async ({
  projectCode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_ALL_FIELDS_BY_PROJECT_API,
    JSON.stringify({...projectCode}),
    tokenData?.access_token,
    {id: projectCode.id},
    signal,
  );
};

export const RQUseGetProjectListData = payload => {
  return useQuery({
    queryKey: ['rq_get_project_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PROJECT_LIST_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
  });
};

export const RQPostCreateJobCodeByProjectCode = async ({
  jobcode,
  tokenData,
  signal,
}) => {

  return rqCreateRequest(
    RQ_CREATE_JOB_CODE_BY_PROJECT_CODE_API,
    JSON.stringify({...jobcode}),
    tokenData?.access_token,
    {id: jobcode.projectId},
    signal,
  );
};

export const RQPutJobCodeUpdate = async ({
  jobCodeData,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_JOB_CODE_BY_PROJECT_CODE_API,
    JSON.stringify({...jobCodeData}),
    tokenData?.access_token,
    {projectId: jobCodeData.projectId, id: jobCodeData.id},
    signal,
  );
};
